.buttons_block {
    margin-top: 20px;
}

.discount_block {
    padding: 40px;
    border: 1px solid #ececec;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #fff;

}

.order_block_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.ic_sidebar {
    width: 50px;
    height: 50px;
    fill: $blue;
    margin-bottom: 20px;
}

.order_block_subtitle {
    line-height: 1.5;
    margin-bottom: 20px;
}

/*.btn-tr {
    padding: 30px 70px;
    border: 1px solid $blue;
    color: $blue;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        color: #fff;
        background: $blue;
    }
}*/

.btn-catalog {
    padding: 30px 30px;
}

@media only screen and (max-width : 540px) {
    .btn-catalog {
        padding: 30px 20px;
    }

    .btn-tr {
        padding: 30px 0px;
        width: 100%;
        text-align: center;
    }
}

.buttons_block {
    margin-bottom: 20px;
    border: 1px solid #ececec;


    .calc_price_btn {
        border-bottom: 1px solid #ececec;
        padding: 20px 20px;
        display: flex;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        transition: all .3s;
        cursor: pointer;
        background-color: #fff;


        &:last-child {
            border-bottom: none;

        }

        &:hover {
            box-shadow: 2px 2px 10px #ececec;
        }

        .ic_sidebar-small {
            width: 17px;
            height: 17px;
            fill: $blue;
            margin-right: 10px;
        }

    }
}


.tizers_page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #ececec;

    .tizer_page {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        .ic_tizer {
            width: 40px;
            height: 40px;
            fill: $blue;
            margin-right: 10px;

        }

        .tizer_title {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            line-height: 1.3;
            font-size: 16px;
            margin-left: 20px;
            text-transform: inherit;
            padding-bottom: 0;
        }
    }

}

.sidebar_info {
    margin: 20px 0;
    font-size: 12px;
    line-height: 1.3;
    font-style: italic;
}
