.about-top {
    background: url('../img/bg2.jpg');




    &__text-wrp {
        margin-top: 80px;
    }

    &__text-sm {
        font-size: 18px;
        color: #fff;
        opacity: .5;
        margin-bottom: 40px;
    }

    h2 {
        color: #fff;
    }

    &__img {
        margin-top: 170px;

        img,
        source {
            display: block;
        }
    }
}

.about-bottom {
    &__info {
        margin-top: -300px;
        padding: 60px 30px 30px 60px;
        box-shadow: 0 0 20px #0000000f;
        background: #fff;
    }

    &__info2 {
        margin-top: 89px;

        h2,
        p {
            margin-bottom: 30px;
        }

        .btn-blue {
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width : 1200px) {
    .about-bottom {
        &__info {
            margin-top: -250px;
        }
    }
}

@media only screen and (max-width : 992px) {
    .about-top {
        &__img {
            margin-top: 50px;
        }
    }

    .about-bottom {
        &__info {
            margin-top: -100px;
        }
    }
}

@media only screen and (max-width : 576px) {

    .about-bottom {
        &__info {
            margin-top: -80px;
            padding: 20px 15px 20px 20px;
        }

        &__info2 {
            margin-top: 50px;
        }
    }
}
