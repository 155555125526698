.header {
    background: #ffff;


    &__top {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        padding: 10px 0;


    }

    .ic_header {
        width: 13px;
        height: 13px;
        fill: $orange;
        margin-right: 5px;

    }

    &__contacts {
        display: flex;

        a {
            color: $font;
            text-decoration: none;
            display: inline-block;
        }

    }

    &__tel {
        margin-right: 20px;
        font-size: 16px;
        font-weight: 600;
    }


    &__bottom {
        display: flex;
        justify-content: space-between;
        /* padding: 30px 0;*/
        align-items: center;



        .logolnk {
            display: flex;

            img {
                width: 130px;
                height: 54px;

            }
        }


    }

    &__dscr {
        font-size: 14px;
        color: #747474;
        line-height: 1.4;
        margin-left: 20px;
    }

}



.fixed_wrp {

    transition: top 1s;
    top: -80px;
    z-index: 60;
}

.fixed {
    background: #fff;
    position: fixed;
    width: 100%;
    border-bottom: 1px none;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.03);
    top: 0px;
    transition: top 1s;
    left: 0;
}

.mfixed {
    margin-top: 80px;
}


.fixed {
    .sf-menu > li {
        padding: 15px 40px 15px 0;
    }

    .sf-menu > li > a {
        padding-right: 0;

    }
}

.mobile_buttons {
    display: none;
}

@media only screen and (max-width : 992px) {
    .mobile_wrp {
        display: flex;
    }

    .mobile_buttons {
        display: flex;
        justify-content: flex-end;

        a {
            background: $blue;
            width: 30px;
            height: 30px !important;
            margin-right: 2px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }

        .ic_mob {
            width: 18px;
            height: 18px;
            fill: #fff;
        }
    }

    .header {
        &__top {
            display: none;



        }

        &__bottom {
            padding: 10px 0;
        }
    }


}

@media only screen and (max-width : 576px) {
    .header {


        &__bottom {
            padding: 10px 0 0 0;
            align-items: flex-start;

            .logolnk {
                img {
                    width: 80px;
                    height: 33px;

                }
            }


        }

        &__dscr {
            display: none;
        }



        .fixed {
            .header__bottom {
                padding: 10px 0 0 0;
                align-items: flex-start;

            }
        }
    }
}
