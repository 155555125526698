.right-block {
    padding: 20px;
    background: #fff;
    margin-top: -60px;

}



.cat-item {
    display: block;
    border-right: solid 1px #ececec;
    border-left: solid 1px #ececec;
    border-bottom: solid 3px $orange;
    margin-bottom: 20px;
    padding-bottom: 20px;
    transition: all .5s;

    &:hover .cat-item__smallimage {
        opacity: 1;
    }

    &__img {
        height: 170px;
        overflow: hidden;
        position: relative;
        display: block;
        margin-bottom: 20px;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }


        &:hover::before {
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            left: 120%;


        }

        &::before {
            position: absolute;
            top: 0;
            left: -100%;
            z-index: 2;
            display: block;
            content: '';
            width: 25%;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 100%);
            transform: skewX(-25deg);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);


        }

    }

    &__wrp {
        display: flex;
        justify-content: space-between;

    }


    &__title {
        color: $font;
        padding: 0 15px;
        display: block;
        line-height: 1.5;
        font-weight: 600;
        overflow: hidden;
        height: 48px;
        flex-basis: 90%;

        &:hover {
            color: $orange;
        }

    }

    &__smallimage {
        transition: all .5s;
        flex-basis: 10%;
        opacity: 0;

        img {
            width: 100%;
        }
    }



}

.ic_catalog {
    width: 25px;
    height: 15px;
    fill: $orange;
}

@media only screen and (max-width : 576px) {

    .forder-2 {
        order: 2
    }

    .right-block {
        order: 1;
    }
}
