.tizers {
    margin-top: 700px;
    position: relative;
    z-index: 30;
}


.tizers_wrp {


    display: flex;
    justify-content: space-between;
    margin-top: -60px;
    position: relative;
    z-index: 10;
    box-shadow: 0px 33px 38px 0px rgba(199, 199, 199, 0.47);
    flex-wrap: wrap;


}

.ic_tizer {
    width: 47px;
    height: 47px;
    fill: $blue;
}

.ic_orange {
    fill: $orange;
}

.tizer1 {
    background: #f4f4f4;
    border-radius: 5px 0 0 5px;
}

.tizer2 {
    background: #efefef;
}

.tizer3 {
    background: $blue;
    color: #fff;
}

.tizer4 {
    background: #f9f9f9;
    border-radius: 0 5px 5px 0;
}

.tizer {
    padding: 40px;
    flex-basis: 25%;
    position: relative;
    overflow: hidden;
}

.tizer_icon {
    width: 60px;
    height: 60px;

    margin-bottom: 10px;
}

.tizer_image {
    position: absolute;
    width: 60%;
    top: -16%;
    z-index: 10;
    left: -5%;
}

.tizer_title {
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 1.5;
    font-size: 26px;
    font-family: 'Oswald', sans-serif;
    color: $font;
    text-transform: uppercase;
    line-height: 1.308;

}

.tizer_lnk {
    cursor: pointer;
    padding-bottom: 3px;
    border-bottom: 1px dotted $font;
    display: inline-block;
    transition: all .3s;

    &:hover {
        color: $font
    }
}



.tizer_descr {
    line-height: 1.5;
    position: relative;
    z-index: 20;

    span {
        font-weight: 700;
    }

}

.tizer_descr--bull {
    padding-top: 50px;
}

@media only screen and (max-width : 992px) {

    .tizer {
        flex-basis: 50%;
    }

    .tizer1 {
        background: #f0f0f0;
        border-radius: 5px 0 0 5px;
    }

    .tizer2 {
        background: #e8e8e8;
    }

    .tizer3 {
        background: $blue;
    }

    .tizer4 {

        border-radius: 0 8px 8px 0;

    }

}



@media only screen and (max-width : 540px) {
    .tizers_wrp {
        margin-top: 750px;
    }


    .tizer {

        flex-basis: 100%;
    }

    .tizer1 {
        background: #f0f0f0;
        border-radius: 5px 5px 0 0;
    }

    .tizer4 {

        border-radius: 0 0 5px 5px;
    }

    .tizer3 {
        background: $blue;
    }

    .tizer_image {
        position: absolute;
        width: 50%;
        top: -20%;
        z-index: 10;
        left: -10%;
    }



    .tizer {
        padding: 15px;

    }

    .tizer_icon {
        width: 40px;
        margin-bottom: 0;
    }
}
