main {
    position: relative;
}

.video-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    background: url(../img/poster.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
}



.video-bg > video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
}


.main-banner {
    position: absolute;
    width: 100%;
    top: 130px;
    left: 0;


    h1 {
        font-size: 48px;
        font-family: 'Akrobat', sans-serif;
        color: #fff;
        font-style: italic;
        margin-bottom: 20px;
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;


    }

    p {
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        padding-right: 100px;
        margin-bottom: 30px;
    }



}

.banner__wrp {
    display: flex;
}

.banner__text {
    flex-basis: 50%;

}

.banner__img {
    flex-basis: 50%;

}


@media only screen and (max-width : 1200px) {
    .main-banner {
        top: 70px;


        h1 {
            font-size: 36px;
        }

        p {
            margin-bottom: 30px;
        }



    }

}







@media screen and (max-device-width: 800px) {
    .video-bg > video {
        display: none;
    }

    .banner__wrp {
        display: flex;
        flex-wrap: wrap;
    }

    .banner__text {
        flex-basis: 100%;
        margin-bottom: 20px;

    }

    .banner__img {
        flex-basis: 100%;

    }



}

@supports (object-fit: cover) {
    .video-bg > video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media only screen and (max-width : 576px) {

    .video-bg {

        height: 800px;
    }

    .main-banner {
        top: 70px;


        h1 {
            font-size: 28px;
        }

        p {
            margin-bottom: 30px;
            padding-right: 0;
        }



    }

}
