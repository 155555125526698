.btn-blue {
    background: $blue;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;
    padding: 25px 40px;
    display: inline-block;
    border: 1px solid $blue;

    &:hover {
        background: $orange;
        border: 1px solid $orange;

        color: #fff;
    }
}


.btn-orange {
    background: $orange;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;
    padding: 25px 40px;
    display: inline-block;
    border: 1px solid $orange;

    &:hover {
        background: $blue;
        border: 1px solid $blue;

        color: #fff;
    }
}

.btn-tr {
    background: transparent;
    color: $font;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;
    padding: 25px 40px;
    display: inline-block;
    border: 1px solid $orange;

    &:hover {
        background: $blue;
        border: 1px solid $blue;
        color: #fff;
    }
}


.btn-blue--ic {

    .ic_btn {
        width: 10px;
        height: 10px;
        fill: $orange;
        margin-left: 10px;
    }

    &:hover .ic_btn {
        fill: $blue;
    }

}

.btn-tr--ic {

    .ic_btn {
        width: 10px;
        height: 10px;
        fill: $orange;
        margin-left: 10px;
    }

    &:hover .ic_btn {
        fill: $orange;
    }

}

@media only screen and (max-width : 1400px) {
    .btn-more {
        padding: 20px 20px;

    }
}
