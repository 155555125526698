body.modal-open {
    padding-right: 0px !important;
}

html {
    overflow-y: scroll !important;
}

.compensate-for-scrollbar {
    padding-right: 0px !important;
}


textarea {
    &:focus {
        border: none;
        outline: none !important;
    }
}

.modal-header {
    justify-content: center;
    position: relative;


}

.graypart {
    background-color: #f6f9fc;
}

.mc-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-content {
    position: relative;
}

.btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
}

#watchme {
    border: none;
    background: transparent;
    box-shadow: none;

    .btn-close {
        right: -30px;
    }

    .modal-content {
        border: none;
        background: transparent;
        box-shadow: none;
    }


}

.modal-title {
    font-family: 'Akrobat', sans-serif;
    font-weight: 800;
    font-size: 38px;
    line-height: 1.2;
    text-transform: uppercase;
    color: $font;
}

.modal-contacts {
    padding: 20px 40px;

    &__heading {
        font-weight: 300;
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 20px;
    }

    &__contacts a {
        display: block;
        font-size: 18px;
        line-height: 1.5;
        font-weight: 700;
        color: $font;
        margin-bottom: 5px;
    }

    &__bottom {
        margin-top: 40px;
        font-size: 12px;
        display: block;
    }
}

.files {
    font-size: 10px;
}

.form-group_modal {
    padding: 40px 40px;

    label {
        display: block;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;

        span {
            color: red;
        }
    }
}

.form_input__modal {
    padding: 13px 0px;
    border: none;
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
    border-radius: 0px;
    width: 100%;
    background: transparent;

    &:focus {
        border-bottom: 2px solid $blue;
    }

}


.form_group__bottom-modal {
    padding: 0px 40px;
    flex-direction: column;
    margin-bottom: 20px;

}

.form_btn__modal {
    padding: 17px 40px;
    background: $blue;
    color: #fff;
    border: 1px solid $blue;
    transition: all .3s;
    display: block;
    margin-bottom: 15px;
    border-radius: 4px;

    &:hover {
        background: #fff;
        color: #244d72;
        border: 1px solid $blue;

    }


}

.politika_modal {
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 20px;
}

.close-menu__modal {
    position: absolute;
    right: 15px;
    top: 30px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    z-index: 900;
}


.close-menu__modal span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $font;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}


.close-menu__modal span:nth-child(1) {
    top: 12px;
    width: 0%;
    left: 50%;
}

.close-menu__modal span:nth-child(2) {
    transform: rotate(45deg);
}

.close-menu__modal span:nth-child(3) {
    transform: rotate(-45deg);
}

.close-menu__modal span:nth-child(4) {
    top: 12px;
    width: 0%;
    left: 50%;
}

.reg_tabs {
    margin: 0 40px;
}

.modal-header_reg {
    border-bottom: 1px solid transparent;
    flex-direction: column;
    padding: 30px 0;

}

.modal-header {
    padding: 40px 20px 20px 40px;
    display: flex;
    flex-direction: column;

    h5 {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.2;

        span {
            font-weight: 300;
            font-size: 18px;

        }
    }

    a {
        display: block;
        font-family: 'Arial';
        font-size: 22px;
        text-decoration: none;
        font-weight: 700;

    }
}

.modal-body {
    position: relative;
    z-index: 700;
    padding: 0;
}


#success,
#success2,
#success3,
#success4,
    {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-image: none;
    text-align: center;
    padding: 40px;
    display: none;
    z-index: 800;
}



.text_success {
    font-size: 34px;
    line-height: 1.5;
}

.radio_title {
    font-size: 12px;
    font-weight: 600;

}

.radio_mtitle {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;

}

.radio_block {
    display: flex;
    font-size: 12px;
    align-items: center;
    margin-bottom: 7px;

    input,
    .radio_title,
    .radio_old-price {
        margin-right: 10px;
    }
}

.radio_old-price {
    font-family: 'Arial';
    text-decoration: line-through;
    opacity: .5;
}

.radio_new-price {
    font-family: 'Arial';
    font-weight: 700;

}

.form_group__bottom-modal p {
    font-size: 11px;
    margin-left: 10px;
}


.modal-content {
    .custom_chek {
        display: flex;
        align-items: center;
        padding: 15px 0px;

        p {

            font-size: 11px;
            margin-left: 10px;
            line-height: 1.3;

        }
    }
}

@media only screen and (max-width: 568px) {

    .modal-title {
        font-size: 22px;
    }

    .form-group_modal,
    .form_group__bottom-modal {
        padding: 20px 20px;

    }

    .modal-header {
        padding-top: 60px;
    }



    .modal_heading {
        padding: 0px 0px;
        margin-bottom: 20px;
    }

    .text_success {
        font-size: 24px;
        line-height: 1.5;
    }



}


.modal-content {
    height: auto !important;
    overflow: hidden !important;
}
