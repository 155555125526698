.ic_btn {
    width: 10px;
    height: 10px;
    fill: #f15a25;
    margin-left: 10px;
    margin-bottom: 3px;
}

.news {
    padding: 60px 0;

    &__sm {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        a {
            color: $font;
            padding: 5px 10px;
            background: #f6f6f6;
            transition: all .3s;

            &:hover {
                color: $orange;
                background: #ececec;
            }
        }
    }

    h2 {
        margin-bottom: 30px;
    }

    p {
        line-height: 1.7;
        margin-bottom: 40px;
    }

}

.news-item {
    box-shadow: 0 0 20px #ececec;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background: #fff;

    &__image {
        height: 170px;
        overflow: hidden;
        position: relative;
        display: block;
        margin-bottom: 20px;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }


        &:hover::before {
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            left: 120%;


        }

        &::before {
            position: absolute;
            top: 0;
            left: -100%;
            z-index: 2;
            display: block;
            content: '';
            width: 25%;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 100%);
            transform: skewX(-25deg);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);


        }

    }

    &__date {
        padding: 0 15px;
        font-size: 14px;
        color: #bdb9b9;

    }

    &__title {
        color: $font;
        padding: 30px 15px;
        display: block;
        line-height: 1.5;
        font-weight: 600;
        height: 104px;
        overflow: hidden;

        &:hover {
            color: $orange;
        }
    }

}
