.content {
    background: #fff;
    padding: 30px;
    margin-top: -60px;

}

.contact_wrp {
    margin: 20px 0;
}

.contact_title {
    color: #9D9D9D;
    margin-bottom: 20px;
    font-size: 14px;
}

.contact_phone {
    display: block;
    font-weight: 600;
    font-size: 22px;
    text-decoration: none;
    margin-bottom: 10px;
    color: $font;

    &:hover {
        color: inherit;
    }
}

.contact_info {
    line-height: 1.5;
}

.contact_mail {
    color: $font;

    &:hover {
        color: $blue;
    }
}

.block--contacts {
    margin: 30px 0;
}

.contact_txt {
    margin: 30px 0;
}

.office_img {


    img {
        height: 456px;
        object-fit: cover;

    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .contact_phone {

        font-size: 18px;

    }
}

@media only screen and (max-width : 768px) {
    .office_img {
        margin: 15px 0;


        img {
            height: auto;
            object-fit: cover;


        }
    }

    .contact_block3 {
        order: 1;

    }

    .contact_block2 {
        order: 2;

    }

    .contact_block1 {
        order: 3;

    }

}
