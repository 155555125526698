.catalog {
    padding: 78px 0;
    background: #f7f7f7;

    h2 {
        font-size: 41px;
        font-family: 'Akrobat', sans-serif;
        font-weight: 700;
        font-style: italic;
    }
}

@media only screen and (max-width : 992px) {
    .catalog {


        h2 {
            font-size: 26px;
            margin: 20px 0;

        }
    }

}


.catalog_dscr {
    padding-left: 30px;
    border-left: 5px solid $blue;
    margin-bottom: 30px;

    p {
        line-height: 1.5;
        margin-bottom: 20px;
    }
}


.pr-prev,
.pr-next {
    border: none;
    padding: 10px 15px;
    background: $blue;
    transition: all .3s;
    color: #fff;
    width: 40px;
    height: 40px;
    position: relative;
    padding: 5px;
    text-align: center;

    &:hover {
        opacity: .7;
    }

}

.pr-next {
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: url('../img/ic-right.svg');
        background-repeat: no-repeat;
        margin: 15px 15px 15px 17px;
    }
}

.pr-prev {
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: url('../img/ic-left.svg');
        background-repeat: no-repeat;
        margin: 15px 15px 15px 17px;
    }
}





.catalog_slider {
    margin-right: 1px;
    border-right: 1px solid #ececec;
    margin-top: 20px;
    margin-bottom: 20px;
}

.item {
    padding: 20px;
    margin-right: -1px;
    background: #fff;
    border: 1px solid #ececec;
    transition: all .3s;

    &:hover {
        border-bottom: 1px solid $orange;


    }


    &__img {
        height: 132px;
        margin-bottom: 30px;

        a {
            display: flex;
            justify-content: center;
            height: 100%;
        }

        img {
            max-height: 100%;
        }
    }

    &__title {
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 20px;
        height: 55px;
        display: block;
        overflow: hidden;
        line-height: 1.3;
        text-decoration: none;
        color: $font;
    }

    &__info {
        display: flex;
        justify-content: space-between;
    }

    &__code {
        color: #b7b9bb;
        font-size: 13px;
        font-weight: 300;
    }

    &__sticker {
        color: #fff;
        font-size: 13px;
        padding: 5px 10px;
        border-radius: 4px;

        &--green {
            background: #37b656;
        }

        &--red {
            background: #df5f5f;

        }


    }

    &__buy {
        display: flex;
        justify-content: center;

    }


    &__btn {
        padding: 15px 30px;
        display: block;
        margin-bottom: 20px;

        &:hover {
            color: #fff;
        }


    }

    &__rating {

        margin-bottom: 25px;

        .ic_rating {
            display: inline-block;
            fill: #FFD83A;
            width: 15px;
            height: 15px;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }


}



@media only screen and (max-width : 768px) {
    .catalog {
        padding: 25px 0;
    }
}
