.mapinfo {
    padding: 60px 0;
    background: #ffff;

    p {
        line-height: 1.7;
    }
}

.ymap {
    width: 100%;
    height: 100%;
    margin: 60px 0;
    border: 1px solid #ececec;
    padding: 10px;
    box-sizing: border-box;
}

#maps {

    height: 500px;
}


.filters-check {

    display: flex;
    width: 100%;
    margin: 0;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    .checkbox-wrap {

        display: flex;

        align-items: center;

        justify-content: center;
        width: 40px;
        flex: 0 0 40px;
        height: 40px;
        border-radius: 2px;
        border: 2px solid #e7e7e7;

        &::after {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            background: green;
            background-size: 100%;
            will-change: transform;

            transform: scale(0);
            opacity: 0;

            transition: all 0.1s linear;
        }
    }

    .checkbox-text {
        display: inline-block;
        margin-top: 13px;
        margin-left: 14px;
        font-size: 14px;
    }

    input {
        &:checked {
            + {
                .checkbox-wrap {
                    &::after {

                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .checkbox-hidden {
        display: none;
    }
}


.filters-check {
    .wrap-orange {
        &::after {
            background: $orange
        }
    }

}

.filters-check {
    .wrap-blue {
        &::after {
            background: $blue;
        }
    }

}

.filters-check {
    .wrap-yellow {
        &::after {
            background: #ffcc00;
        }
    }

}

.ymaps-2-1-79-ground-pane {

    filter: grayscale(1);
    -ms-filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);

}
