.wrapper {
    position: relative;
}

.header-wrap {
    position: relative;
}



.mega-sub {
    margin-bottom: 30px;
}

.mega-sub i {
    margin-right: 5px;
}

.sf-menu {
    position: static;
    float: none;



}

.sf-menu .sf-mega {
    background-color: #fff;
    padding: 25px 0;
}

.sf-menu .sf-mega ul {
    display: block;
    float: none;
    position: relative;
    box-shadow: none;
}

.sf-menu li {
    white-space: normal;
    position: static;
    background: #fff !important;

    &:hover {
        background: #fff !important;
    }
}

.sf-menu li a {
    &:hover {
        color: $orange;
    }

}


.sf-menu > li > ul {
    width: 300px;
    left: auto;
    line-height: 1.3;
}

.sf-menu > li > ul li .sf-with-ul::after {
    font-family: "FontAwesome";
    content: '';
    right: 1em;
    position: absolute;
}


.dropdwn:after {
    content: '';
    right: -20px;
    position: absolute;
    transform: rotate(90deg);
    top: 0px;
    bottom: 0;
    margin: auto;
    background: url('../img/dropdwn.svg');
    background-repeat: no-repeat;
    width: 7px;
    height: 7px;
}


.responsive-img {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.toggle-mnu {
    display: block;
    width: 28px;
    height: 28px;
    margin-top: 14px;
}

.toggle-mnu span:after,
.toggle-mnu span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
}

.toggle-mnu span:after {
    top: 18px;
}

.toggle-mnu span {
    position: relative;
    display: block;
}

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
    width: 100%;
    height: 2px;
    background-color: #666;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 2px;
}

.toggle-mnu.on span {
    background-color: transparent;
}

.toggle-mnu.on span:before {
    -webkit-transform: rotate(45deg) translate(-1px, 0px);
    -ms-transform: rotate(45deg) translate(-1px, 0px);
    transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
    -webkit-transform: rotate(-45deg) translate(6px, -7px);
    -ms-transform: rotate(-45deg) translate(6px, -7px);
    transform: rotate(-45deg) translate(6px, -7px);
}

.toggle-mnu {
    float: right;
    margin-top: 5px;
    position: relative;
    z-index: 2;
    margin-right: 15px;
}

.sf-menu a {
    color: #333;
    background-color: #fff;
}

.sf-menu > li {
    background-color: #fff;
    padding: 30px 40px 30px 0;

    &:last-child {
        padding-right: 0;
    }
}


.sf-menu > li > a {

    border-bottom: 5px solid transparent;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: #333;
    font-size: 16px;
    padding-right: 0;
    font-weight: 600;


}

.sf-menu > li > a + * {
    border-top: $orange solid 5px;
}

.sf-menu > li > a + * a {
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    border-bottom: #ddd 1px solid;
}

.sf-menu > li > a + * a:hover {
    color: $orange;
}

.sf-menu > li:hover > a {
    background-color: #fff;
}

.sf-menu .container-fluid {
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.sf-menu .row {
    margin-right: -15px !important;
    margin-left: -15px !important;
}

.sf-menu .col-lg-1,
.sf-menu .col-lg-10,
.sf-menu .col-lg-11,
.sf-menu .col-lg-12,
.sf-menu .col-lg-2,
.sf-menu .col-lg-3,
.sf-menu .col-lg-4,
.sf-menu .col-lg-5,
.sf-menu .col-lg-6,
.sf-menu .col-lg-7,
.sf-menu .col-lg-8,
.sf-menu .col-lg-9,
.sf-menu .col-md-1,
.sf-menu .col-md-10,
.sf-menu .col-md-11,
.sf-menu .col-md-12,
.sf-menu .col-md-2,
.sf-menu .col-md-3,
.sf-menu .col-md-4,
.sf-menu .col-md-5,
.sf-menu .col-md-6,
.sf-menu .col-md-7,
.sf-menu .col-md-8,
.sf-menu .col-md-9,
.sf-menu .col-sm-1,
.sf-menu .col-sm-10,
.sf-menu .col-sm-11,
.sf-menu .col-sm-12,
.sf-menu .col-sm-2,
.sf-menu .col-sm-3,
.sf-menu .col-sm-4,
.sf-menu .col-sm-5,
.sf-menu .col-sm-6,
.sf-menu .col-sm-7,
.sf-menu .col-sm-8,
.sf-menu .col-sm-9,
.sf-menu .col-xs-1,
.sf-menu .col-xs-10,
.sf-menu .col-xs-11,
.sf-menu .col-xs-12,
.sf-menu .col-xs-2,
.sf-menu .col-xs-3,
.sf-menu .col-xs-4,
.sf-menu .col-xs-5,
.sf-menu .col-xs-6,
.sf-menu .col-xs-7,
.sf-menu .col-xs-8,
.sf-menu .col-xs-9 {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.menu-new-item {
    margin-bottom: 30px;
}

.menu-new-item h3 {
    margin: 15px 0;
}

.menu-new-item a {
    padding: 0;
    font-size: 18px;
    text-decoration: underline;
    color: $orange;
}

.menu-new-item p {
    font-size: 14px;
    color: #666;
}

.sf-menu > li > a + * .menu-new-item a {
    border-bottom: none;
}

.sf-menu > li > a + * .menu-new-item a:hover {
    color: #333;
}

.mega-sub img {
    display: inline-block;
    margin-right: 10px;
    max-width: 30px;
    height: auto;
}

.small-sub img {
    display: inline-block;
    margin-right: 10px;
    max-width: 30px;
    height: auto;
}


.mega-sub a {
    display: flex;
    align-items: center;
}

.small-sub a {
    display: flex;
    align-items: center;
}


.mobile_item {
    display: none;
}

html.mm-pagedim.mm-opening #mm-blocker,
html.mm-pagedim-white.mm-opening #mm-blocker,
html.mm-pagedim-black.mm-opening #mm-blocker {
    opacity: 0.3;
    transition: opacity 0.4s ease 0.4s;



    &::after {
        content: "";
        background: url(../img/ic-close.svg) no-repeat 0 0;
        color: #fff;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        z-index: 99999;
        top: 10px;
        left: -30px;


    }
}



@media only screen and (max-width: 990px) {
    .on {
        position: fixed;
        left: 0;
        top: 0;
        width: 20px;
        background: #00000073;
        margin-top: 30px;
        height: 20px;
        color: #fff;
        padding-left: 3px;
        display: none;

        span:after,
        span:before {
            background-color: #fff;

        }

    }



    .header_wrp {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        right: 15px;

    }

    .top-menu_block,
    .search_block {
        display: none;
    }

    .dropdwn:after {
        display: none;
    }

    .mobile_item {
        display: flex !important;
    }

    .top-mnu ul {
        display: none;
    }

    .header-wrap {
        padding-bottom: 10px;
    }
}


@media only screen and (min-width: 991px) {
    .toggle-mnu {
        display: none;
    }

    .toggle-mnu,
    #mobile-menu,
    html.mm-blocking #mm-blocker {
        display: none;
    }

    html.mm-opening .mm-slideout {
        -webkit-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.mega-sub {
    top: 0px !important;
}

.sf-js-enabled {
    margin-top: 0;
    margin-bottom: 0;
}
