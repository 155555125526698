@charset "UTF-8";
.header {
  background: #ffff;
}
.header__top {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 10px 0;
}
.header .ic_header {
  width: 13px;
  height: 13px;
  fill: #f15a25;
  margin-right: 5px;
}
.header__contacts {
  display: flex;
}
.header__contacts a {
  color: #282828;
  text-decoration: none;
  display: inline-block;
}
.header__tel {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
}
.header__bottom {
  display: flex;
  justify-content: space-between;
  /* padding: 30px 0;*/
  align-items: center;
}
.header__bottom .logolnk {
  display: flex;
}
.header__bottom .logolnk img {
  width: 130px;
  height: 54px;
}
.header__dscr {
  font-size: 14px;
  color: #747474;
  line-height: 1.4;
  margin-left: 20px;
}

.fixed_wrp {
  transition: top 1s;
  top: -80px;
  z-index: 60;
}

.fixed {
  background: #fff;
  position: fixed;
  width: 100%;
  border-bottom: 1px none;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.03);
  top: 0px;
  transition: top 1s;
  left: 0;
}

.mfixed {
  margin-top: 80px;
}

.fixed .sf-menu > li {
  padding: 15px 40px 15px 0;
}
.fixed .sf-menu > li > a {
  padding-right: 0;
}

.mobile_buttons {
  display: none;
}

@media only screen and (max-width: 992px) {
  .mobile_wrp {
    display: flex;
  }

  .mobile_buttons {
    display: flex;
    justify-content: flex-end;
  }
  .mobile_buttons a {
    background: #1b1564;
    width: 30px;
    height: 30px !important;
    margin-right: 2px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .mobile_buttons .ic_mob {
    width: 18px;
    height: 18px;
    fill: #fff;
  }

  .header__top {
    display: none;
  }
  .header__bottom {
    padding: 10px 0;
  }
}
@media only screen and (max-width: 576px) {
  .header__bottom {
    padding: 10px 0 0 0;
    align-items: flex-start;
  }
  .header__bottom .logolnk img {
    width: 80px;
    height: 33px;
  }
  .header__dscr {
    display: none;
  }
  .header .fixed .header__bottom {
    padding: 10px 0 0 0;
    align-items: flex-start;
  }
}
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
  background: #191919;
  padding: 60px 0;
}
.footer hr {
  opacity: 0.2;
  margin: 40px 0;
}
.footer__desc {
  font-size: 14px;
  color: #a8a8a8;
  line-height: 1.7;
  margin: 20px 0;
  opacity: 0.7;
}
.footer__title {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 30px;
  line-height: 1.5;
}
.footer ul {
  margin-bottom: 30px;
}
.footer ul > li > a {
  display: block;
  color: #a8a8a8;
  padding: 10px 0;
}
.footer ul > li > a:hover {
  color: #fff;
}
.footer .ic_footer {
  fill: #f15a25;
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 10px;
}
.footer__adress {
  display: flex;
  margin-bottom: 20px;
}
.footer__adress p {
  color: #a8a8a8;
  padding: 0;
  margin: 0;
}
.footer__phone a {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
.footer__phone .ic_footer {
  margin-bottom: 8px;
}
.footer__mail a {
  display: inline-block;
  color: #a8a8a8;
  padding: 10px 0;
}
.footer__mail a:hover {
  color: #fff;
}
.footer__social {
  display: flex;
}
.footer__social a {
  padding: 10px;
  background: #424242;
  border-radius: 4px;
  margin-right: 7px;
  opacity: 0.5;
}
.footer__social a:hover {
  opacity: 1;
}
.footer__social .ic_social {
  width: 31px;
  height: 31px;
  fill: #fff;
}
.footer__form {
  position: relative;
  width: 45%;
}
.footer__form input {
  width: 100%;
  height: 50px;
  background: #2b2b2b;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 5px 40px 5px 10px;
  transition: all 0.5s;
  width: 100%;
  border: 1px solid #2b2b2b;
}
.footer__form input:focus {
  border: 1px solid gray;
}
.footer__form button {
  border: none;
  background: transparent;
  box-shadow: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 10px;
}
.footer__form .ic_footerform {
  height: 20px;
  width: 20px;
  fill: #fff;
  transition: all 0.3s;
}
.footer__form .ic_footerform:hover {
  opacity: 0.5;
}
.footer__copy {
  font-size: 15px;
  color: #464646;
  line-height: 1.5;
}
.footer__submit {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .footer__submit {
    flex-wrap: wrap;
  }
  .footer__social {
    margin-bottom: 20px;
  }
  .footer__form {
    width: 100%;
    margin-bottom: 20px;
  }
}
.wrapper {
  position: relative;
}

.header-wrap {
  position: relative;
}

.mega-sub {
  margin-bottom: 30px;
}

.mega-sub i {
  margin-right: 5px;
}

.sf-menu {
  position: static;
  float: none;
}

.sf-menu .sf-mega {
  background-color: #fff;
  padding: 25px 0;
}

.sf-menu .sf-mega ul {
  display: block;
  float: none;
  position: relative;
  box-shadow: none;
}

.sf-menu li {
  white-space: normal;
  position: static;
  background: #fff !important;
}
.sf-menu li:hover {
  background: #fff !important;
}

.sf-menu li a:hover {
  color: #f15a25;
}

.sf-menu > li > ul {
  width: 300px;
  left: auto;
  line-height: 1.3;
}

.sf-menu > li > ul li .sf-with-ul::after {
  font-family: "FontAwesome";
  content: "\f105";
  right: 1em;
  position: absolute;
}

.dropdwn:after {
  content: "";
  right: -20px;
  position: absolute;
  transform: rotate(90deg);
  top: 0px;
  bottom: 0;
  margin: auto;
  background: url("../img/dropdwn.svg");
  background-repeat: no-repeat;
  width: 7px;
  height: 7px;
}

.responsive-img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.toggle-mnu {
  display: block;
  width: 28px;
  height: 28px;
  margin-top: 14px;
}

.toggle-mnu span:after,
.toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
}

.toggle-mnu span:after {
  top: 18px;
}

.toggle-mnu span {
  position: relative;
  display: block;
}

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #666;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

.toggle-mnu.on span {
  background-color: transparent;
}

.toggle-mnu.on span:before {
  -webkit-transform: rotate(45deg) translate(-1px, 0px);
  -ms-transform: rotate(45deg) translate(-1px, 0px);
  transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
  -webkit-transform: rotate(-45deg) translate(6px, -7px);
  -ms-transform: rotate(-45deg) translate(6px, -7px);
  transform: rotate(-45deg) translate(6px, -7px);
}

.toggle-mnu {
  float: right;
  margin-top: 5px;
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

.sf-menu a {
  color: #333;
  background-color: #fff;
}

.sf-menu > li {
  background-color: #fff;
  padding: 30px 40px 30px 0;
}
.sf-menu > li:last-child {
  padding-right: 0;
}

.sf-menu > li > a {
  border-bottom: 5px solid transparent;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #333;
  font-size: 16px;
  padding-right: 0;
  font-weight: 600;
}

.sf-menu > li > a + * {
  border-top: #f15a25 solid 5px;
}

.sf-menu > li > a + * a {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border-bottom: #ddd 1px solid;
}

.sf-menu > li > a + * a:hover {
  color: #f15a25;
}

.sf-menu > li:hover > a {
  background-color: #fff;
}

.sf-menu .container-fluid {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.sf-menu .row {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.sf-menu .col-lg-1,
.sf-menu .col-lg-10,
.sf-menu .col-lg-11,
.sf-menu .col-lg-12,
.sf-menu .col-lg-2,
.sf-menu .col-lg-3,
.sf-menu .col-lg-4,
.sf-menu .col-lg-5,
.sf-menu .col-lg-6,
.sf-menu .col-lg-7,
.sf-menu .col-lg-8,
.sf-menu .col-lg-9,
.sf-menu .col-md-1,
.sf-menu .col-md-10,
.sf-menu .col-md-11,
.sf-menu .col-md-12,
.sf-menu .col-md-2,
.sf-menu .col-md-3,
.sf-menu .col-md-4,
.sf-menu .col-md-5,
.sf-menu .col-md-6,
.sf-menu .col-md-7,
.sf-menu .col-md-8,
.sf-menu .col-md-9,
.sf-menu .col-sm-1,
.sf-menu .col-sm-10,
.sf-menu .col-sm-11,
.sf-menu .col-sm-12,
.sf-menu .col-sm-2,
.sf-menu .col-sm-3,
.sf-menu .col-sm-4,
.sf-menu .col-sm-5,
.sf-menu .col-sm-6,
.sf-menu .col-sm-7,
.sf-menu .col-sm-8,
.sf-menu .col-sm-9,
.sf-menu .col-xs-1,
.sf-menu .col-xs-10,
.sf-menu .col-xs-11,
.sf-menu .col-xs-12,
.sf-menu .col-xs-2,
.sf-menu .col-xs-3,
.sf-menu .col-xs-4,
.sf-menu .col-xs-5,
.sf-menu .col-xs-6,
.sf-menu .col-xs-7,
.sf-menu .col-xs-8,
.sf-menu .col-xs-9 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.menu-new-item {
  margin-bottom: 30px;
}

.menu-new-item h3 {
  margin: 15px 0;
}

.menu-new-item a {
  padding: 0;
  font-size: 18px;
  text-decoration: underline;
  color: #f15a25;
}

.menu-new-item p {
  font-size: 14px;
  color: #666;
}

.sf-menu > li > a + * .menu-new-item a {
  border-bottom: none;
}

.sf-menu > li > a + * .menu-new-item a:hover {
  color: #333;
}

.mega-sub img {
  display: inline-block;
  margin-right: 10px;
  max-width: 30px;
  height: auto;
}

.small-sub img {
  display: inline-block;
  margin-right: 10px;
  max-width: 30px;
  height: auto;
}

.mega-sub a {
  display: flex;
  align-items: center;
}

.small-sub a {
  display: flex;
  align-items: center;
}

.mobile_item {
  display: none;
}

html.mm-pagedim.mm-opening #mm-blocker,
html.mm-pagedim-white.mm-opening #mm-blocker,
html.mm-pagedim-black.mm-opening #mm-blocker {
  opacity: 0.3;
  transition: opacity 0.4s ease 0.4s;
}
html.mm-pagedim.mm-opening #mm-blocker::after,
html.mm-pagedim-white.mm-opening #mm-blocker::after,
html.mm-pagedim-black.mm-opening #mm-blocker::after {
  content: "";
  background: url(../img/ic-close.svg) no-repeat 0 0;
  color: #fff;
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 99999;
  top: 10px;
  left: -30px;
}

@media only screen and (max-width: 990px) {
  .on {
    position: fixed;
    left: 0;
    top: 0;
    width: 20px;
    background: #00000073;
    margin-top: 30px;
    height: 20px;
    color: #fff;
    padding-left: 3px;
    display: none;
  }
  .on span:after,
.on span:before {
    background-color: #fff;
  }

  .header_wrp {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 15px;
  }

  .top-menu_block,
.search_block {
    display: none;
  }

  .dropdwn:after {
    display: none;
  }

  .mobile_item {
    display: flex !important;
  }

  .top-mnu ul {
    display: none;
  }

  .header-wrap {
    padding-bottom: 10px;
  }
}
@media only screen and (min-width: 991px) {
  .toggle-mnu {
    display: none;
  }

  .toggle-mnu,
#mobile-menu,
html.mm-blocking #mm-blocker {
    display: none;
  }

  html.mm-opening .mm-slideout {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
.mega-sub {
  top: 0px !important;
}

.sf-js-enabled {
  margin-top: 0;
  margin-bottom: 0;
}

main {
  position: relative;
}

.video-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
  background: url(../img/poster.jpg) no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}

.video-bg > video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.main-banner {
  position: absolute;
  width: 100%;
  top: 130px;
  left: 0;
}
.main-banner h1 {
  font-size: 48px;
  font-family: "Akrobat", sans-serif;
  color: #fff;
  font-style: italic;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
}
.main-banner p {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  padding-right: 100px;
  margin-bottom: 30px;
}

.banner__wrp {
  display: flex;
}

.banner__text {
  flex-basis: 50%;
}

.banner__img {
  flex-basis: 50%;
}

@media only screen and (max-width: 1200px) {
  .main-banner {
    top: 70px;
  }
  .main-banner h1 {
    font-size: 36px;
  }
  .main-banner p {
    margin-bottom: 30px;
  }
}
@media screen and (max-device-width: 800px) {
  .video-bg > video {
    display: none;
  }

  .banner__wrp {
    display: flex;
    flex-wrap: wrap;
  }

  .banner__text {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .banner__img {
    flex-basis: 100%;
  }
}
@supports (object-fit: cover) {
  .video-bg > video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 576px) {
  .video-bg {
    height: 800px;
  }

  .main-banner {
    top: 70px;
  }
  .main-banner h1 {
    font-size: 28px;
  }
  .main-banner p {
    margin-bottom: 30px;
    padding-right: 0;
  }
}
.btn-blue {
  background: #1b1564;
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  padding: 25px 40px;
  display: inline-block;
  border: 1px solid #1b1564;
}
.btn-blue:hover {
  background: #f15a25;
  border: 1px solid #f15a25;
  color: #fff;
}

.btn-orange {
  background: #f15a25;
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  padding: 25px 40px;
  display: inline-block;
  border: 1px solid #f15a25;
}
.btn-orange:hover {
  background: #1b1564;
  border: 1px solid #1b1564;
  color: #fff;
}

.btn-tr {
  background: transparent;
  color: #282828;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  padding: 25px 40px;
  display: inline-block;
  border: 1px solid #f15a25;
}
.btn-tr:hover {
  background: #1b1564;
  border: 1px solid #1b1564;
  color: #fff;
}

.btn-blue--ic .ic_btn {
  width: 10px;
  height: 10px;
  fill: #f15a25;
  margin-left: 10px;
}
.btn-blue--ic:hover .ic_btn {
  fill: #1b1564;
}

.btn-tr--ic .ic_btn {
  width: 10px;
  height: 10px;
  fill: #f15a25;
  margin-left: 10px;
}
.btn-tr--ic:hover .ic_btn {
  fill: #f15a25;
}

@media only screen and (max-width: 1400px) {
  .btn-more {
    padding: 20px 20px;
  }
}
.tizers {
  margin-top: 700px;
  position: relative;
  z-index: 30;
}

.tizers_wrp {
  display: flex;
  justify-content: space-between;
  margin-top: -60px;
  position: relative;
  z-index: 10;
  box-shadow: 0px 33px 38px 0px rgba(199, 199, 199, 0.47);
  flex-wrap: wrap;
}

.ic_tizer {
  width: 47px;
  height: 47px;
  fill: #1b1564;
}

.ic_orange {
  fill: #f15a25;
}

.tizer1 {
  background: #f4f4f4;
  border-radius: 5px 0 0 5px;
}

.tizer2 {
  background: #efefef;
}

.tizer3 {
  background: #1b1564;
  color: #fff;
}

.tizer4 {
  background: #f9f9f9;
  border-radius: 0 5px 5px 0;
}

.tizer {
  padding: 40px;
  flex-basis: 25%;
  position: relative;
  overflow: hidden;
}

.tizer_icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.tizer_image {
  position: absolute;
  width: 60%;
  top: -16%;
  z-index: 10;
  left: -5%;
}

.tizer_title {
  font-weight: 700;
  padding-bottom: 20px;
  line-height: 1.5;
  font-size: 26px;
  font-family: "Oswald", sans-serif;
  color: #282828;
  text-transform: uppercase;
  line-height: 1.308;
}

.tizer_lnk {
  cursor: pointer;
  padding-bottom: 3px;
  border-bottom: 1px dotted #282828;
  display: inline-block;
  transition: all 0.3s;
}
.tizer_lnk:hover {
  color: #282828;
}

.tizer_descr {
  line-height: 1.5;
  position: relative;
  z-index: 20;
}
.tizer_descr span {
  font-weight: 700;
}

.tizer_descr--bull {
  padding-top: 50px;
}

@media only screen and (max-width: 992px) {
  .tizer {
    flex-basis: 50%;
  }

  .tizer1 {
    background: #f0f0f0;
    border-radius: 5px 0 0 5px;
  }

  .tizer2 {
    background: #e8e8e8;
  }

  .tizer3 {
    background: #1b1564;
  }

  .tizer4 {
    border-radius: 0 8px 8px 0;
  }
}
@media only screen and (max-width: 540px) {
  .tizers_wrp {
    margin-top: 750px;
  }

  .tizer {
    flex-basis: 100%;
  }

  .tizer1 {
    background: #f0f0f0;
    border-radius: 5px 5px 0 0;
  }

  .tizer4 {
    border-radius: 0 0 5px 5px;
  }

  .tizer3 {
    background: #1b1564;
  }

  .tizer_image {
    position: absolute;
    width: 50%;
    top: -20%;
    z-index: 10;
    left: -10%;
  }

  .tizer {
    padding: 15px;
  }

  .tizer_icon {
    width: 40px;
    margin-bottom: 0;
  }
}
.catalog {
  padding: 78px 0;
  background: #f7f7f7;
}
.catalog h2 {
  font-size: 41px;
  font-family: "Akrobat", sans-serif;
  font-weight: 700;
  font-style: italic;
}

@media only screen and (max-width: 992px) {
  .catalog h2 {
    font-size: 26px;
    margin: 20px 0;
  }
}
.catalog_dscr {
  padding-left: 30px;
  border-left: 5px solid #1b1564;
  margin-bottom: 30px;
}
.catalog_dscr p {
  line-height: 1.5;
  margin-bottom: 20px;
}

.pr-prev,
.pr-next {
  border: none;
  padding: 10px 15px;
  background: #1b1564;
  transition: all 0.3s;
  color: #fff;
  width: 40px;
  height: 40px;
  position: relative;
  padding: 5px;
  text-align: center;
}
.pr-prev:hover,
.pr-next:hover {
  opacity: 0.7;
}

.pr-next:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../img/ic-right.svg");
  background-repeat: no-repeat;
  margin: 15px 15px 15px 17px;
}

.pr-prev:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../img/ic-left.svg");
  background-repeat: no-repeat;
  margin: 15px 15px 15px 17px;
}

.catalog_slider {
  margin-right: 1px;
  border-right: 1px solid #ececec;
  margin-top: 20px;
  margin-bottom: 20px;
}

.item {
  padding: 20px;
  margin-right: -1px;
  background: #fff;
  border: 1px solid #ececec;
  transition: all 0.3s;
}
.item:hover {
  border-bottom: 1px solid #f15a25;
}
.item__img {
  height: 132px;
  margin-bottom: 30px;
}
.item__img a {
  display: flex;
  justify-content: center;
  height: 100%;
}
.item__img img {
  max-height: 100%;
}
.item__title {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 20px;
  height: 55px;
  display: block;
  overflow: hidden;
  line-height: 1.3;
  text-decoration: none;
  color: #282828;
}
.item__info {
  display: flex;
  justify-content: space-between;
}
.item__code {
  color: #b7b9bb;
  font-size: 13px;
  font-weight: 300;
}
.item__sticker {
  color: #fff;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 4px;
}
.item__sticker--green {
  background: #37b656;
}
.item__sticker--red {
  background: #df5f5f;
}
.item__buy {
  display: flex;
  justify-content: center;
}
.item__btn {
  padding: 15px 30px;
  display: block;
  margin-bottom: 20px;
}
.item__btn:hover {
  color: #fff;
}
.item__rating {
  margin-bottom: 25px;
}
.item__rating .ic_rating {
  display: inline-block;
  fill: #FFD83A;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.item__rating .ic_rating:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .catalog {
    padding: 25px 0;
  }
}
.about-top {
  background: url("../img/bg2.jpg");
}
.about-top__text-wrp {
  margin-top: 80px;
}
.about-top__text-sm {
  font-size: 18px;
  color: #fff;
  opacity: 0.5;
  margin-bottom: 40px;
}
.about-top h2 {
  color: #fff;
}
.about-top__img {
  margin-top: 170px;
}
.about-top__img img,
.about-top__img source {
  display: block;
}

.about-bottom__info {
  margin-top: -300px;
  padding: 60px 30px 30px 60px;
  box-shadow: 0 0 20px #0000000f;
  background: #fff;
}
.about-bottom__info2 {
  margin-top: 89px;
}
.about-bottom__info2 h2,
.about-bottom__info2 p {
  margin-bottom: 30px;
}
.about-bottom__info2 .btn-blue {
  margin-right: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
  .about-bottom__info {
    margin-top: -250px;
  }
}
@media only screen and (max-width: 992px) {
  .about-top__img {
    margin-top: 50px;
  }

  .about-bottom__info {
    margin-top: -100px;
  }
}
@media only screen and (max-width: 576px) {
  .about-bottom__info {
    margin-top: -80px;
    padding: 20px 15px 20px 20px;
  }
  .about-bottom__info2 {
    margin-top: 50px;
  }
}
.ic_btn {
  width: 10px;
  height: 10px;
  fill: #f15a25;
  margin-left: 10px;
  margin-bottom: 3px;
}

.news {
  padding: 60px 0;
}
.news__sm {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.news__sm a {
  color: #282828;
  padding: 5px 10px;
  background: #f6f6f6;
  transition: all 0.3s;
}
.news__sm a:hover {
  color: #f15a25;
  background: #ececec;
}
.news h2 {
  margin-bottom: 30px;
}
.news p {
  line-height: 1.7;
  margin-bottom: 40px;
}

.news-item {
  box-shadow: 0 0 20px #ececec;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background: #fff;
}
.news-item__image {
  height: 170px;
  overflow: hidden;
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.news-item__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.news-item__image:hover::before {
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  left: 120%;
}
.news-item__image::before {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  display: block;
  content: "";
  width: 25%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}
.news-item__date {
  padding: 0 15px;
  font-size: 14px;
  color: #bdb9b9;
}
.news-item__title {
  color: #282828;
  padding: 30px 15px;
  display: block;
  line-height: 1.5;
  font-weight: 600;
  height: 104px;
  overflow: hidden;
}
.news-item__title:hover {
  color: #f15a25;
}

.mapinfo {
  padding: 60px 0;
  background: #ffff;
}
.mapinfo p {
  line-height: 1.7;
}

.ymap {
  width: 100%;
  height: 100%;
  margin: 60px 0;
  border: 1px solid #ececec;
  padding: 10px;
  box-sizing: border-box;
}

#maps {
  height: 500px;
}

.filters-check {
  display: flex;
  width: 100%;
  margin: 0;
  cursor: pointer;
}
.filters-check:not(:last-child) {
  margin-bottom: 15px;
}
.filters-check .checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  flex: 0 0 40px;
  height: 40px;
  border-radius: 2px;
  border: 2px solid #e7e7e7;
}
.filters-check .checkbox-wrap::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: green;
  background-size: 100%;
  will-change: transform;
  transform: scale(0);
  opacity: 0;
  transition: all 0.1s linear;
}
.filters-check .checkbox-text {
  display: inline-block;
  margin-top: 13px;
  margin-left: 14px;
  font-size: 14px;
}
.filters-check input:checked + .checkbox-wrap::after {
  transform: scale(1);
  opacity: 1;
}
.filters-check .checkbox-hidden {
  display: none;
}

.filters-check .wrap-orange::after {
  background: #f15a25;
}

.filters-check .wrap-blue::after {
  background: #1b1564;
}

.filters-check .wrap-yellow::after {
  background: #ffcc00;
}

.ymaps-2-1-79-ground-pane {
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
}

body.modal-open {
  padding-right: 0px !important;
}

html {
  overflow-y: scroll !important;
}

.compensate-for-scrollbar {
  padding-right: 0px !important;
}

textarea:focus {
  border: none;
  outline: none !important;
}

.modal-header {
  justify-content: center;
  position: relative;
}

.graypart {
  background-color: #f6f9fc;
}

.mc-wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  position: relative;
}

.btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

#watchme {
  border: none;
  background: transparent;
  box-shadow: none;
}
#watchme .btn-close {
  right: -30px;
}
#watchme .modal-content {
  border: none;
  background: transparent;
  box-shadow: none;
}

.modal-title {
  font-family: "Akrobat", sans-serif;
  font-weight: 800;
  font-size: 38px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #282828;
}

.modal-contacts {
  padding: 20px 40px;
}
.modal-contacts__heading {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.modal-contacts__contacts a {
  display: block;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  color: #282828;
  margin-bottom: 5px;
}
.modal-contacts__bottom {
  margin-top: 40px;
  font-size: 12px;
  display: block;
}

.files {
  font-size: 10px;
}

.form-group_modal {
  padding: 40px 40px;
}
.form-group_modal label {
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
.form-group_modal label span {
  color: red;
}

.form_input__modal {
  padding: 13px 0px;
  border: none;
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
  border-radius: 0px;
  width: 100%;
  background: transparent;
}
.form_input__modal:focus {
  border-bottom: 2px solid #1b1564;
}

.form_group__bottom-modal {
  padding: 0px 40px;
  flex-direction: column;
  margin-bottom: 20px;
}

.form_btn__modal {
  padding: 17px 40px;
  background: #1b1564;
  color: #fff;
  border: 1px solid #1b1564;
  transition: all 0.3s;
  display: block;
  margin-bottom: 15px;
  border-radius: 4px;
}
.form_btn__modal:hover {
  background: #fff;
  color: #244d72;
  border: 1px solid #1b1564;
}

.politika_modal {
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.close-menu__modal {
  position: absolute;
  right: 15px;
  top: 30px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  z-index: 900;
}

.close-menu__modal span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #282828;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.close-menu__modal span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.close-menu__modal span:nth-child(2) {
  transform: rotate(45deg);
}

.close-menu__modal span:nth-child(3) {
  transform: rotate(-45deg);
}

.close-menu__modal span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.reg_tabs {
  margin: 0 40px;
}

.modal-header_reg {
  border-bottom: 1px solid transparent;
  flex-direction: column;
  padding: 30px 0;
}

.modal-header {
  padding: 40px 20px 20px 40px;
  display: flex;
  flex-direction: column;
}
.modal-header h5 {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
}
.modal-header h5 span {
  font-weight: 300;
  font-size: 18px;
}
.modal-header a {
  display: block;
  font-family: "Arial";
  font-size: 22px;
  text-decoration: none;
  font-weight: 700;
}

.modal-body {
  position: relative;
  z-index: 700;
  padding: 0;
}

#success,
#success2,
#success3,
#success4 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: none;
  text-align: center;
  padding: 40px;
  display: none;
  z-index: 800;
}

.text_success {
  font-size: 34px;
  line-height: 1.5;
}

.radio_title {
  font-size: 12px;
  font-weight: 600;
}

.radio_mtitle {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.radio_block {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-bottom: 7px;
}
.radio_block input,
.radio_block .radio_title,
.radio_block .radio_old-price {
  margin-right: 10px;
}

.radio_old-price {
  font-family: "Arial";
  text-decoration: line-through;
  opacity: 0.5;
}

.radio_new-price {
  font-family: "Arial";
  font-weight: 700;
}

.form_group__bottom-modal p {
  font-size: 11px;
  margin-left: 10px;
}

.modal-content .custom_chek {
  display: flex;
  align-items: center;
  padding: 15px 0px;
}
.modal-content .custom_chek p {
  font-size: 11px;
  margin-left: 10px;
  line-height: 1.3;
}

@media only screen and (max-width: 568px) {
  .modal-title {
    font-size: 22px;
  }

  .form-group_modal,
.form_group__bottom-modal {
    padding: 20px 20px;
  }

  .modal-header {
    padding-top: 60px;
  }

  .modal_heading {
    padding: 0px 0px;
    margin-bottom: 20px;
  }

  .text_success {
    font-size: 24px;
    line-height: 1.5;
  }
}
.modal-content {
  height: auto !important;
  overflow: hidden !important;
}

.heading {
  background: #1b1564;
  padding: 30px 0px 90px 0;
}
.heading h1 {
  font-family: "Akrobat", sans-serif;
  font-size: 38px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 20px;
  margin-top: 0;
}

.breadcrumbs li {
  display: inline-block;
  font-size: 15px;
  color: #ffffffa6;
}
.breadcrumbs li a {
  color: #fff;
  text-decoration: none;
}
.breadcrumbs li a:hover {
  color: #ffffffad;
}
.breadcrumbs li + li::before {
  color: #ffffffad;
  content: "/";
  padding: 0 5px;
}

.buttons_block {
  margin-top: 20px;
}

.discount_block {
  padding: 40px;
  border: 1px solid #ececec;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #fff;
}

.order_block_title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.ic_sidebar {
  width: 50px;
  height: 50px;
  fill: #1b1564;
  margin-bottom: 20px;
}

.order_block_subtitle {
  line-height: 1.5;
  margin-bottom: 20px;
}

/*.btn-tr {
    padding: 30px 70px;
    border: 1px solid $blue;
    color: $blue;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        color: #fff;
        background: $blue;
    }
}*/
.btn-catalog {
  padding: 30px 30px;
}

@media only screen and (max-width: 540px) {
  .btn-catalog {
    padding: 30px 20px;
  }

  .btn-tr {
    padding: 30px 0px;
    width: 100%;
    text-align: center;
  }
}
.buttons_block {
  margin-bottom: 20px;
  border: 1px solid #ececec;
}
.buttons_block .calc_price_btn {
  border-bottom: 1px solid #ececec;
  padding: 20px 20px;
  display: flex;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.3s;
  cursor: pointer;
  background-color: #fff;
}
.buttons_block .calc_price_btn:last-child {
  border-bottom: none;
}
.buttons_block .calc_price_btn:hover {
  box-shadow: 2px 2px 10px #ececec;
}
.buttons_block .calc_price_btn .ic_sidebar-small {
  width: 17px;
  height: 17px;
  fill: #1b1564;
  margin-right: 10px;
}

.tizers_page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ececec;
}
.tizers_page .tizer_page {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.tizers_page .tizer_page:last-child {
  margin-bottom: 0;
}
.tizers_page .tizer_page .ic_tizer {
  width: 40px;
  height: 40px;
  fill: #1b1564;
  margin-right: 10px;
}
.tizers_page .tizer_page .tizer_title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  font-size: 16px;
  margin-left: 20px;
  text-transform: inherit;
  padding-bottom: 0;
}

.sidebar_info {
  margin: 20px 0;
  font-size: 12px;
  line-height: 1.3;
  font-style: italic;
}

.right-block {
  padding: 20px;
  background: #fff;
  margin-top: -60px;
}

.cat-item {
  display: block;
  border-right: solid 1px #ececec;
  border-left: solid 1px #ececec;
  border-bottom: solid 3px #f15a25;
  margin-bottom: 20px;
  padding-bottom: 20px;
  transition: all 0.5s;
}
.cat-item:hover .cat-item__smallimage {
  opacity: 1;
}
.cat-item__img {
  height: 170px;
  overflow: hidden;
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.cat-item__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cat-item__img:hover::before {
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  left: 120%;
}
.cat-item__img::before {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  display: block;
  content: "";
  width: 25%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}
.cat-item__wrp {
  display: flex;
  justify-content: space-between;
}
.cat-item__title {
  color: #282828;
  padding: 0 15px;
  display: block;
  line-height: 1.5;
  font-weight: 600;
  overflow: hidden;
  height: 48px;
  flex-basis: 90%;
}
.cat-item__title:hover {
  color: #f15a25;
}
.cat-item__smallimage {
  transition: all 0.5s;
  flex-basis: 10%;
  opacity: 0;
}
.cat-item__smallimage img {
  width: 100%;
}

.ic_catalog {
  width: 25px;
  height: 15px;
  fill: #f15a25;
}

@media only screen and (max-width: 576px) {
  .forder-2 {
    order: 2;
  }

  .right-block {
    order: 1;
  }
}
.slider-wrapper {
  display: flex;
  overflow: hidden;
  max-height: 700px;
}
.slider-wrapper .slider-thumb li {
  padding: 10px;
  border: 1px solid #ececec;
  margin-bottom: 5px;
  margin-right: 0px;
  height: 113px;
  width: 113px;
  position: relative;
}
.slider-wrapper .slider-thumb li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-wrapper .slider-thumb .slick-prev3 {
  left: unset;
  top: 0.5em;
}
.slider-wrapper .slider-thumb .slick-next3 {
  top: unset;
  right: unset;
  bottom: -0.5em;
}
.slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-next3 {
  z-index: 1;
  height: 1em;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  transition: opacity 300ms ease;
  font-size: 1.5em;
  color: #fff;
  border: none;
}
.slider-wrapper .slider-thumb .slick-prev3::before,
.slider-wrapper .slider-thumb .slick-next3::before {
  content: "";
}
.slider-wrapper .slider-thumb .slick-prev3::hover,
.slider-wrapper .slider-thumb .slick-next3::hover {
  opacity: 0.5;
}
.slider-wrapper .slider-preview li {
  padding: 10px;
  width: 514px;
  height: 518px;
  border: 1px solid #ececec;
  margin-left: 5px;
}
.slider-wrapper .slider-preview li a {
  outline: none;
}
.slider-wrapper .slider-preview li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
Медиа запросы для карточки товара*/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 750px;
  }
  .slider-wrapper .slider-thumb li {
    padding: 10px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 0px;
    width: 231px !important;
    height: 220px !important;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    left: unset;
    top: 0.5em;
  }
  .slider-wrapper .slider-thumb .slick-next3 {
    top: unset;
    right: unset;
    bottom: -0.5em;
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-next3 {
    z-index: 1;
    height: 1em;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
    transition: opacity 300ms ease;
    font-size: 1.5em;
    color: #fff;
    border: none;
  }
  .slider-wrapper .slider-thumb .slick-prev3::before,
.slider-wrapper .slider-thumb .slick-next3::before {
    content: "";
  }
  .slider-wrapper .slider-thumb .slick-prev3::hover,
.slider-wrapper .slider-thumb .slick-next3::hover {
    opacity: 0.5;
  }
  .slider-wrapper .slider-preview li {
    padding: 10px;
    width: 700px;
    height: 720px;
    border: 1px solid #ececec;
    margin-left: 5px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 720px;
  }
  .slider-wrapper .slider-thumb li {
    padding: 10px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 0px;
    width: 231px !important;
    height: 220px !important;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    left: unset;
    top: 0.5em;
  }
  .slider-wrapper .slider-thumb .slick-next3 {
    top: unset;
    right: unset;
    bottom: -0.5em;
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-next3 {
    z-index: 1;
    height: 1em;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
    transition: opacity 300ms ease;
    font-size: 1.5em;
    color: #fff;
    border: none;
  }
  .slider-wrapper .slider-thumb .slick-prev3::before,
.slider-wrapper .slider-thumb .slick-next3::before {
    content: "";
  }
  .slider-wrapper .slider-thumb .slick-prev3::hover,
.slider-wrapper .slider-thumb .slick-next3::hover {
    opacity: 0.5;
  }
  .slider-wrapper .slider-preview li {
    padding: 10px;
    width: 700px;
    height: 720px;
    border: 1px solid #ececec;
    margin-left: 5px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 767px) {
  * {
    min-height: 0;
    min-width: 0;
  }

  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 800px;
    flex-direction: column-reverse;
  }
  .slider-wrapper .slider-thumb {
    max-width: 100%;
  }
  .slider-wrapper .slider-thumb li {
    padding: 3px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 5px;
    max-height: 100px;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    top: 0.5em;
    left: 0;
  }
  .slider-wrapper .slider-thumb .slick-prev3 .fa::before {
    content: "\f104";
  }
  .slider-wrapper .slider-thumb .slick-next3 {
    top: 0.5em;
    right: 0;
  }
  .slider-wrapper .slider-thumb .slick-next3 .fa::before {
    content: "\f105";
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-next3 {
    top: 0;
    width: 1em;
    height: 100px;
    border: none;
    position: absolute;
  }
  .slider-wrapper .slider-preview li {
    padding: 0px;
    border: 1px solid #ececec;
    margin-left: 0px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 540px) {
  * {
    min-height: 0;
    min-width: 0;
  }

  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 800px;
    flex-direction: column-reverse;
  }
  .slider-wrapper .slider-thumb {
    max-width: 100%;
    padding: 0 23px 0 27px;
    text-align: center;
  }
  .slider-wrapper .slider-thumb li {
    padding: 3px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 5px;
    max-height: 100px;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    top: 0.5em;
    left: 0;
  }
  .slider-wrapper .slider-thumb .slick-prev3 .fa::before {
    content: "\f104";
  }
  .slider-wrapper .slider-thumb .slick-next3 {
    top: 0.5em;
    right: 0;
  }
  .slider-wrapper .slider-thumb .slick-next3 .fa::before {
    content: "\f105";
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-next3 {
    top: 0;
    width: 1em;
    height: 100px;
    border: none;
    position: absolute;
  }
  .slider-wrapper .slider-preview li {
    padding: 0px;
    border: 1px solid #ececec;
    margin-left: 0px;
    height: 350px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-card {
  padding: 30px 20px;
  background: #fff;
  margin-top: -60px;
}

.infocard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.infocard_sticker {
  background: #1b1564;
  color: #fff;
  padding: 7px 15px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infocard_marker {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.infocard_marker span {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.infocard_marker__green {
  color: green;
}
.infocard_marker__green span {
  background: green;
}

.ic-card {
  margin-right: 0;
}

.card-title h1 {
  font-size: 24px;
  font-weight: 700;
}

.product_prices {
  display: flex;
  margin-bottom: 20px;
}

.product_prices-new {
  font-size: 23px;
  margin-right: 15px;
  font-weight: 600;
}

.product_prices-old {
  color: darkgray;
  text-decoration: line-through;
  margin-right: 15px;
}

.product_btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 60px;
}

.questions_block {
  position: relative;
  padding: 10px;
  border: 1px solid #ececec;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.questions_block a:hover {
  opacity: 0.7;
}

.questions_block--text {
  padding: 7px;
  background: #fff;
  font-size: 13px;
  color: darkgray;
  left: 1.5;
}

.q_link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(90deg, #ffc328 18%, #ff8d00 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ic_quest {
  width: 17px;
  height: 17px;
  fill: #fff;
}

hr {
  background-color: #ececec;
  opacity: 1;
}

.tizers-card {
  display: flex;
  justify-content: space-between;
}
.tizers-card .tizer {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  flex-basis: auto;
}

.tizer_img {
  margin-bottom: 10px;
}

.tizer_img img {
  max-width: 40px;
  height: auto;
}

.tizer_txt {
  line-height: 1.3;
  font-size: 14px;
}

.nav-card {
  margin-top: 30px;
}

article,
.description_card,
.card-data__row {
  padding: 20px 0;
}
article p,
.description_card p,
.card-data__row p {
  line-height: 1.5;
  margin-bottom: 7px;
}
article ul li,
.description_card ul li,
.card-data__row ul li {
  line-height: 1.5;
  margin: 7px;
}
article ul span,
.description_card ul span,
.card-data__row ul span {
  width: 7px;
  height: 7px;
  display: inline-block;
  background: #f15a25;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 3px;
}
article .main_text,
.description_card .main_text,
.card-data__row .main_text {
  padding: 15px 15px 15px 40px;
  border-left: 3px solid #f15a25;
  background: #ececec;
  margin: 15px 0;
  display: block;
}

.nav-tabs {
  border-bottom: 1px solid #ececec;
}

.ic_product {
  width: 50px;
  height: 50px;
  fill: #1b1564;
}

nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #f7f7f7;
  border-color: #fff #fff #fff;
  border-bottom: 3px solid #1b1564;
  font-weight: 600;
  padding: 15px 20px;
  cursor: pointer;
}

nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  font-size: 17px;
  cursor: pointer;
  padding: 15px 20px;
  color: gray;
}
nav-tabs .nav-item.show .nav-link:hover,
.nav-tabs .nav-link:hover {
  border-color: #fff #fff #1b1564 #fff;
}

nav-tabs .nav-item.show .nav-link:focus,
.nav-tabs .nav-link:focus {
  border-bottom: 3px solid #244d72;
}

@media only screen and (max-width: 1200px) {
  .infocard {
    margin-top: 30px;
  }

  .product_prices {
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) {
  .tizers-card {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 470px) {
  .questions_block {
    margin-top: 30px;
    margin-left: 0;
  }
}
.content {
  background: #fff;
  padding: 30px;
  margin-top: -60px;
}

.contact_wrp {
  margin: 20px 0;
}

.contact_title {
  color: #9D9D9D;
  margin-bottom: 20px;
  font-size: 14px;
}

.contact_phone {
  display: block;
  font-weight: 600;
  font-size: 22px;
  text-decoration: none;
  margin-bottom: 10px;
  color: #282828;
}
.contact_phone:hover {
  color: inherit;
}

.contact_info {
  line-height: 1.5;
}

.contact_mail {
  color: #282828;
}
.contact_mail:hover {
  color: #1b1564;
}

.block--contacts {
  margin: 30px 0;
}

.contact_txt {
  margin: 30px 0;
}

.office_img img {
  height: 456px;
  object-fit: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .contact_phone {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .office_img {
    margin: 15px 0;
  }
  .office_img img {
    height: auto;
    object-fit: cover;
  }

  .contact_block3 {
    order: 1;
  }

  .contact_block2 {
    order: 2;
  }

  .contact_block1 {
    order: 3;
  }
}