.slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 700px;


    .slider-thumb {
        li {
            padding: 10px;
            border: 1px solid #ececec;
            margin-bottom: 5px;
            margin-right: 0px;
            height: 113px;
            width: 113px;
            position: relative;


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }



        .slick-prev3 {
            left: unset;
            top: 0.5em;
        }

        .slick-next3 {
            top: unset;
            right: unset;
            bottom: -0.5em;
        }

        .slick-prev3,
        .slick-next3 {
            z-index: 1;
            height: 1em;
            width: 100%;
            background-color: #000;
            opacity: 0.3;
            transition: opacity 300ms ease;
            font-size: 1.5em;
            color: #fff;
            border: none;

            &::before {
                content: '';

            }

            &::hover {
                opacity: 0.5;


            }


        }
    }

    .slider-preview {

        li {
            padding: 10px;
            width: 514px;
            height: 518px;
            border: 1px solid #ececec;
            margin-left: 5px;

            a {
                outline: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;


            }
        }


    }

}











/*
Медиа запросы для карточки товара*/

@media only screen and (min-width: 992px) and (max-width: 1200px) {



    .slider-wrapper {
        display: flex;
        overflow: hidden;
        max-height: 750px;


        .slider-thumb {
            li {
                padding: 10px;
                border: 1px solid #ececec;
                margin-bottom: 5px;
                margin-right: 0px;
                width: 231px !important;
                height: 220px !important;


                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


            }



            .slick-prev3 {
                left: unset;
                top: 0.5em;
            }

            .slick-next3 {
                top: unset;
                right: unset;
                bottom: -0.5em;
            }

            .slick-prev3,
            .slick-next3 {
                z-index: 1;
                height: 1em;
                width: 100%;
                background-color: #000;
                opacity: 0.3;
                transition: opacity 300ms ease;
                font-size: 1.5em;
                color: #fff;
                border: none;

                &::before {
                    content: '';

                }

                &::hover {
                    opacity: 0.5;


                }


            }
        }

        .slider-preview {
            li {
                padding: 10px;
                width: 700px;
                height: 720px;
                border: 1px solid #ececec;
                margin-left: 5px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;


                }
            }


        }

    }






}

@media only screen and (min-width: 768px) and (max-width: 979px) {



    .slider-wrapper {
        display: flex;
        overflow: hidden;
        max-height: 720px;


        .slider-thumb {
            li {
                padding: 10px;
                border: 1px solid #ececec;
                margin-bottom: 5px;
                margin-right: 0px;
                width: 231px !important;
                height: 220px !important;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


            }



            .slick-prev3 {
                left: unset;
                top: 0.5em;
            }

            .slick-next3 {
                top: unset;
                right: unset;
                bottom: -0.5em;
            }

            .slick-prev3,
            .slick-next3 {
                z-index: 1;
                height: 1em;
                width: 100%;
                background-color: #000;
                opacity: 0.3;
                transition: opacity 300ms ease;
                font-size: 1.5em;
                color: #fff;
                border: none;

                &::before {
                    content: '';

                }

                &::hover {
                    opacity: 0.5;


                }


            }
        }

        .slider-preview {
            li {
                padding: 10px;
                width: 700px;
                height: 720px;
                border: 1px solid #ececec;
                margin-left: 5px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;


                }
            }


        }

    }
}








@media only screen and (max-width: 767px) {
    * {
        min-height: 0;
        min-width: 0;
    }




    .slider-wrapper {
        display: flex;
        overflow: hidden;
        max-height: 800px;
        flex-direction: column-reverse;




        .slider-thumb {
            max-width: 100%;

            li {
                padding: 3px;
                border: 1px solid #ececec;
                margin-bottom: 5px;
                margin-right: 5px;
                max-height: 100px;




                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


            }



            .slick-prev3 {

                top: 0.5em;
                left: 0;

                .fa {
                    &::before {
                        content: "\f104";
                    }
                }
            }

            .slick-next3 {
                top: 0.5em;
                right: 0;

                .fa {
                    &::before {
                        content: "\f105";
                    }
                }
            }

            .slick-prev3,
            .slick-next3 {
                top: 0;
                width: 1em;
                height: 100px;
                border: none;
                position: absolute;





            }
        }

        .slider-preview {
            li {
                padding: 0px;
                border: 1px solid #ececec;
                margin-left: 0px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }


        }

    }
}

@media only screen and (max-width: 540px) {
    * {
        min-height: 0;
        min-width: 0;
    }




    .slider-wrapper {
        display: flex;
        overflow: hidden;
        max-height: 800px;
        flex-direction: column-reverse;




        .slider-thumb {
            max-width: 100%;
            padding: 0 23px 0 27px;
            text-align: center;

            li {
                padding: 3px;
                border: 1px solid #ececec;
                margin-bottom: 5px;
                margin-right: 5px;
                max-height: 100px;




                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


            }



            .slick-prev3 {

                top: 0.5em;
                left: 0;

                .fa {
                    &::before {
                        content: "\f104";
                    }
                }
            }

            .slick-next3 {
                top: 0.5em;
                right: 0;

                .fa {
                    &::before {
                        content: "\f105";
                    }
                }
            }

            .slick-prev3,
            .slick-next3 {
                top: 0;
                width: 1em;
                height: 100px;
                border: none;
                position: absolute;





            }
        }

        .slider-preview {
            li {
                padding: 0px;
                border: 1px solid #ececec;
                margin-left: 0px;
                height: 350px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }


        }

    }
}
