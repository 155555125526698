//прижимаем футер

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}



.footer {
    margin-top: auto;
    background: #191919;
    padding: 60px 0;

    hr {
        opacity: .2;
        margin: 40px 0;
    }

    &__desc {
        font-size: 14px;
        color: #a8a8a8;
        line-height: 1.7;
        margin: 20px 0;
        opacity: .7;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 30px;
        line-height: 1.5;
    }

    ul {
        margin-bottom: 30px;
    }

    ul > li > a {
        display: block;
        color: #a8a8a8;
        padding: 10px 0;

        &:hover {
            color: #fff;
        }
    }

    .ic_footer {
        fill: $orange;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 10px;
    }


    &__adress {
        display: flex;
        margin-bottom: 20px;

        p {
            color: #a8a8a8;
            padding: 0;
            margin: 0;
        }
    }

    &__phone {
        a {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            display: inline-block;
            margin-bottom: 20px;
        }

        .ic_footer {
            margin-bottom: 8px;
        }
    }

    &__mail {
        a {
            display: inline-block;
            color: #a8a8a8;
            padding: 10px 0;


            &:hover {
                color: #fff;
            }
        }
    }

    &__social {
        display: flex;

        a {
            padding: 10px;
            background: #424242;
            border-radius: 4px;
            margin-right: 7px;
            opacity: .5;

            &:hover {
                opacity: 1;
            }
        }

        .ic_social {
            width: 31px;
            height: 31px;
            fill: #fff;

        }

    }

    &__form {
        position: relative;
        width: 45%;

        input {
            width: 100%;
            height: 50px;
            background: #2b2b2b;
            color: #fff;
            box-shadow: none;
            border: none;
            padding: 5px 40px 5px 10px;
            transition: all .5s;
            width: 100%;
            border: 1px solid #2b2b2b;

            &:focus {
                border: 1px solid gray;

            }
        }

        button {
            border: none;
            background: transparent;
            box-shadow: none;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 10px;
        }

        .ic_footerform {
            height: 20px;
            width: 20px;
            fill: #fff;
            transition: all .3s;

            &:hover {
                opacity: .5;
            }
        }
    }

    &__copy {
        font-size: 15px;
        color: #464646;
        line-height: 1.5;

    }

    &__submit {
        display: flex;
    }
}

@media only screen and (max-width : 768px) {
    .footer {
        &__submit {

            flex-wrap: wrap;
        }

        &__social {
            margin-bottom: 20px;

        }

        &__form {

            width: 100%;
            margin-bottom: 20px;
        }
    }
}
